// common---
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DynamicsContactForm from "../components/contactForm/dynamicsContactForm"
import MediaKit from "../components/mediaKit"
// --- end common

import BannerSection from "../components/bannerSection"
import MultiUp from "../components/multiUp"
import MultiUpItem from "../components/multiUpItem"

// Image imports
import exploreLeadImage from "../images/EXPLORE-GBH_Dec20.jpg"
import eventsLeadImage from "../images/PBS-WGBH-Events-sponsorship.jpg"
import pledgeLeadImage from "../images/AP_Pledge_volunteers.png"

const exploreHeader = {
  title: "EXPLORE GBH",
  image: exploreLeadImage,
}

const eventsHeader = {
  title: "GBH Events",
  image: eventsLeadImage,
}

const pledgeHeader = {
  title: "Corporate Pledge Drive",
  image: pledgeLeadImage,
}

const PlatformsPage = () => (
  <Layout>
    <Seo
      title="Other Platforms"
      isTitleExtended={true}
      description="Extend your brand's impact and reach through sponsorship of GBH's monthly magazine, rich roster of events, or a live pledge night."
    />

    <BannerSection
      category="Additional Platforms"
      header="Benefit from Our Unique Sponsorship Opportunities"
      subHeader="Companies extend their impact through sponsorship of EXPLORE GBH member magazine, GBH’s rich roster of events, or a live pledge night with your employees in the GBH studios."
    />

    <div className="list-section">
      <MultiUp type="three-up">
        <MultiUpItem
          header={exploreHeader}
          content="One of the most popular member benefits, EXPLORE GBH is sent to over 162,000 GBH members each month."
        />
        <MultiUpItem
          header={eventsHeader}
          content="GBH's rich roster of events offers unique sponsorship opportunities that put sponsors face to face with their target audience."
        />
        <MultiUpItem
          header={pledgeHeader}
          content="Reach thousands of viewers across New England as the sponsor of a GBH pledge drive to increase your visibility and enhance employee relations."
        />
      </MultiUp>
    </div>

    <MediaKit />
    <DynamicsContactForm />
  </Layout>
)

export default PlatformsPage
